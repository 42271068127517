<template>
	<div class="other">
		<v-container>
			<div class="logo text-center m-auto">
				<v-img
					class="logo-er-1"
					src="../assets/logo-er-1-gold.png"
					lazy-src="../assets/logo-er-1-gold.png"
					max-width="200px"
					max-height="200px"
				>
				</v-img>
				<p>by dr. Erna Purnamasari</p>
			</div>
			<v-list dense>
				<v-list-item>
					<v-list-item-icon>
						<v-icon>mdi-phone-outline</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title @click="$router.push('/contact')"
							><span class="other-text">Hubungi kami</span>
							<v-icon>mdi-chevron-right</v-icon></v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon>mdi-exit-to-app</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title @click="logout"
							><span class="other-text">Log out</span>
							<v-icon>mdi-chevron-right</v-icon></v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<BottomNav />
		</v-container>
	</div>
</template>
<script>
	import BottomNav from "../components/BottomNav.vue";
	import { AUTH_LOGOUT } from "../store/actions/auth";
	export default {
		name: "Other",
		components: { BottomNav },
		data: () => ({
			value: 1,
			drawer: null,
		}),
		methods: {
			logout: function() {
				window.plugins.googleplus.disconnect(function(msg) {
					console.log(msg); // do something useful instead of alerting
				});
				this.$store.dispatch(AUTH_LOGOUT).then(() => {
					this.$router.push("/login");
				});
			},
		},
	};
</script>

<style>
	.other .logo {
		display: block;
		margin-bottom: 51.93px;
	}
	.other span.other-text {
		font-size: 18px;
		color: #000000;
		padding: 10px 0;
	}
	.other .logo .v-image {
		margin: 10px auto;
	}
	.other .v-list--dense .v-list-item .v-list-item__title {
		font-size: 16px !important;
	}
	.other .v-list--dense .v-list-item .v-list-item__title i {
		float: right;
		right: 0;
		position: absolute;
	}
	.other .v-list--dense .v-list-item .v-list-item__title {
		width: 100%;
		align-items: center;
		display: flex;
		flex: 1 1 100%;
		font-size: 16px !important;
	}
	.v-list .v-list-item:last-child .v-list-item__content,
	.v-list .v-list-item:last-child .v-list-item__icon {
		border-bottom: 0px;
	}
	.other .v-list-item__icon {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		display: flex;
		align-items: center;
		height: 53px !important;
	}
	.other a.v-list-item.v-list-item--link.theme--light {
		border-bottom: 1px solid #d5d5d5;
	}
	.other v-list-item.theme--light {
		padding: 0;
		display: flex;
		align-items: center;
		height: 48px;
	}
	.other a.v-list-item.v-list-item--link.theme--light:last-child {
		border-bottom: 0;
	}
	.other a.v-list-item.v-list-item--link.theme--light {
		height: 53px;
	}
	.other {
		min-height: 100vh;
		background: #f8f8f8;
	}
	.other .theme--light.v-list {
		background: transparent;
	}
	.v-list-item.theme--light:first-child {
		border-bottom: 1px solid #d5d5d5;
	}
</style>
